<template>
  <div>
    <v-row>
      <v-col class="d-flex" cols="8">
        <app-text-search-field v-model="searchParams.text" label="Pesquisar" @filter="showFilter = !showFilter" />
        <v-btn class="ml-4" large @click="select()" color="primary">
          <app-icon>search</app-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="showFilter">
      <v-col>
        <app-select v-model="searchParams.position" :items="positions" label="Cargo" />
      </v-col>
      <v-col>
        <app-text-field v-model="searchParams.start_date" label=" Data Inicial" type="date" />
      </v-col>

      <v-col>
        <app-text-field v-model="searchParams.end_date" label=" Data Final" type="date" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="8">
        <v-data-table :headers="headers" :items="commissions.data" hide-default-footer disable-sort
          :items-per-page="-1">
          <template v-slot:[`item.name`]="{ item }">
            {{ item.salesman.name }}
          </template>
          <template v-slot:[`item.commissionable.description`]="{ item }">
            {{ item.commissionable.description }}
          </template>

          <template v-slot:[`item.created_at`]="{ item }">
            {{ $format.dateBr(item.created_at) }}
          </template>

          <template v-slot:[`item.calc_base`]="{ item }">
            {{ $format.moneyBr(item.calc_base) }}
          </template>

          <template v-slot:[`item.fee`]="{ item }">
            {{ $format.decimal(item.fee) }}%
          </template>

          <template v-slot:[`item.value`]="{ item }">
            {{ $format.moneyBr(item.value) }}
          </template>

          <template v-slot:[`item.status`]="{ item }">
            <v-chip small :color="statuses[item.status].color">
              {{ statuses[item.status].text }}
            </v-chip>
          </template>
        </v-data-table>

        <app-pagination @click="select($event)" :data="commissions" />
      </v-col>
      <v-col>
        <h3>Comissão por vendedor</h3>
        <template v-for="(commission, index) in commission_sum">
          <v-card class="my-2" flat outlined :key="index">
            <v-card-title class="pt-2">
              <app-icon color="primary" class="mr-2">monetization_on </app-icon>
              <b>{{ commission.salesman.name }}</b>
            </v-card-title>
            <v-card-subtitle class="d-flex justify-space-between pb-2">
              <div>
                <div>
                  <span>Comissão :</span>
                  <b>{{ $format.moneyBr(commission.value) }}</b>
                </div>

                <div>
                  <span>Base Calculo :</span>
                  <b>{{ $format.moneyBr(commission.calc_base) }}</b>
                </div>

                <div>
                  <span>Taxa :</span>
                  <b>{{ $format.decimal(commission.fee) }} %</b>
                </div>
              </div>
            </v-card-subtitle>
          </v-card>
        </template>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showFilter: false,
      searchParams: {
        text: null,
        start_date: null,
        end_date: null,
        statuses: ["paid"],
      },
      commissions: {},
      commission_sum: [],

      headers: [
        { text: "Nome", value: "name", align: "center" },
        { text: "Descrição", value: "commissionable.description", align: "center" },
        { text: "Data", value: "created_at", align: "center" },
        { text: "Bas. Calculo", value: "calc_base", align: "center" },
        { text: "Taxa", value: "fee", align: "center" },
        { text: "Valor", value: "value", align: "center" },
        { text: "Status", value: "status", align: "center" },
      ],

      statuses: {
        paid: {
          text: "Pago",
          color: "success",
        },
        canceled: {
          text: "Cancelado",
          color: "error",
        },
      },
      positions: [
        { text: "Vendedor", value: "vendedor" },
        { text: "Gerente", value: "gerente" },
        { text: "Caixa", value: "caixa" },
        { text: "Auxiliar administrativo", value: "auxiliar_administrativo" },
        { text: "Estoquista", value: "estoquista" },
        { text: "Comprador", value: "comprador" },
        { text: "Motorista", value: "motorista" },
        { text: "Entregador", value: "entregador" },
        { text: "Cobrador", value: "cobrador" },
        { text: "Outros", value: "outros" },
        { text: "Logistica", value: "logistica" },
      ],
    };
  },

  mounted() {
    this.select();
  },

  methods: {
    select(page) {
      this.searchParams.page = page;

      this.$loading.start();
      this.$http
        .index("sale/commission", this.searchParams)
        .then((response) => {
          this.commissions = response.commissions;
          this.commission_sum = response.commission_sum;

          this.$loading.finish();
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },
  },
};
</script>

<style></style>